import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from '../../app/store/reducers/currencySlice';

export default function HotDealList({ data }) {
  // const descriptionLines = data.description.split(' ');
  // const oneLine = descriptionLines.slice(0, 10).join(' ');
  const navigate = useNavigate();

  // const truncatedDescription =
  //   descriptionLines.length > 10 ? `${oneLine}....` : oneLine;
  const [showButton, setShowButton] = useState(true);
  const currencies = useSelector(getAllCurrencies);

  // console.log(data);
  const handleButtonClick = () => {
    navigate(`itineraries/view/${data._id}`);
    setShowButton(false);
  };
  const formDuration = (duration) => {
    const days = parseInt(duration) + 1;
    const nights = days - 1;
    return `${days} days ${nights} nights`;
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full overflow-hidden bg-white rounded-lg shadow-lg">
        <div>
          <img
            className="object-cover w-full h-48"
            src={data?.images?.[0] || ''}
            alt={data.itineraryName}
          />
          <h3 className="p-4 text-lg font-bold text-gray-700">
            {data.itineraryName}
          </h3>
        </div>
        <div className="p-4 text-gray-500">
          <div className="flex flex-col justify-end ">
            <p className="font-semibold ">
              {data.duration + 'Days/ ' + (data.duration - 1) + 'Nights'}
            </p>
            <p className="font-semibold ">
              {currencies[data.country][0].code} ~
              {parseFloat(
                data.total_adult_amount / data.numberOfAdults, 
              ).toFixed(2)}{' '}
              Per Person 
            </p>
            <button
              type="submit"
              className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2"
              onClick={handleButtonClick}
            >
              Explore
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
