import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

export default function ContactUs() {
  return (
    <>
      <div className="max-w-4xl p-6 mx-auto mt-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <h1 className="mb-4 text-4xl font-bold text-center text-gray-800">Contact Us</h1>
        <p className="mb-6 text-center text-gray-600">
          Fill in your contact details and query information, and we'll get back to you as soon as possible.
        </p>
        
        <div className="space-y-6">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">Address</h2>
            <p className="text-gray-600">07, Narsinghpura Road, Mandsaur, Madhya Pradesh, 458001</p>
          </div>
          
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">Email</h2>
            <p className="text-gray-600">info@skyhighmds.com</p>
          </div>
          
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">Phone</h2>
            <p className="text-gray-600">+91 971529406180</p>
          </div>
          
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">Escalation Contact</h2>
            <p className="text-gray-600">
              For escalations, contact us at{' '}
              <a href="mailto:info@skyhighmds.com" className="text-blue-500 underline">
                info@skyhighmds.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
