import { Box, Grid, Rating, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const ActivityTimeline = ({ activityData, day }) => {
  // console.log(activityData);
  const [data, setData] = useState();

  useEffect(() => {
    setData(activityData);
  }, [activityData]);

  return (
    <Box
      sx={{
        borderRadius: "12px",
        background: "#f5f5f5",
        padding: "1rem",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
      mt={"1rem"}
    >
      <Grid container spacing={4}>
        <Grid item>
          {data?.images.length > 0 && (
            <img
              src={data?.images[0] || ""}
              alt={data?.activityName}
              width={300}
              style={{ borderRadius: "8px" }}
            />
          )}
        </Grid>
        <Grid item>
          <Typography variant="h5" style={{ marginBottom: "0.5rem" }}>
            {data?.activityName?.toUpperCase()}
          </Typography>
          <Typography style={{ marginBottom: "0.5rem" }}>
            Country: {data?.country}
          </Typography>
          <Typography style={{ marginBottom: "0.5rem" }}>
            Location: {data?.location}
          </Typography>
          <Typography style={{ marginBottom: "0.5rem" }}>
            Transport Type:{" "}
            {data?.transportType === "PT" ? "Private Transfer" 
              : data?.transportType === "WOT"  ? data?.type === 'Meal'  ? "Meal Transfer Prices" 
                : data?.type === 'Entrance'
                  ? "Tickets Only"
                  : data.type === 'Experience'
                  ? 'Entrance by own expenses'
                  : 'Without Transfer'
              : data?.transportType === "ST"
              ? "Shared Transfer"
              : "Unknown"}
          </Typography>

          <Typography style={{ marginBottom: "0.5rem" }}>
            Activity Type: {data?.type}
          </Typography>
          {/* <Typography style={{ marginBottom: "0.5rem" }}>
            Total Cost: {data?.totalCost}
          </Typography> */}
          <Typography style={{ marginBottom: "0.5rem" }}>
            Description: {data?.description}
          </Typography>
          {/* <Rating
            precision={0.5}
            readOnly
            value={parseInt(data?.rating || 0)}
            style={{ marginBottom: "0.5rem" }}
          /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActivityTimeline;
