import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useIdToken } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase.config';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../store/reducers/authSlice';
import './AuthGuard.css'; 

const AuthGuard = () => {
  const [user, loading] = useIdToken(auth);
  const currentUser = useSelector(selectCurrentUser);

  if (loading) {
    return (
      <div className="loader-overlay">
        <div className="spinner"></div>
      </div>
    );
  }

  if (user && currentUser && currentUser.verified === true) {
    return <Outlet />;
  } else {
    return <Navigate to={'/signin'} replace={true} />;
  }
};

export default AuthGuard;
