import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FeaturedItineraries = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/dashboard/itineraries/create');
  };
  const handleRedirectViewPage = () => {
    navigate('/dashboard/itineraries');
  };

  const slides = [
    {
      image: 'https://ik.imagekit.io/freelance/1.jpg?updatedAt=1742157965185',
      title: 'Discover New Adventures',
      description: 'Plan your next journey with custom itineraries tailored just for you.',
    },
    {
      image: 'https://ik.imagekit.io/freelance/bali.jpg?updatedAt=1720282973237',
      title: 'Escape to Bali',
      description: 'Explore the stunning beaches, cultural sites, and breathtaking landscapes of Bali.',
    },
    {
      image: 'https://ik.imagekit.io/freelance/dubai.jpg?updatedAt=1725910887856',
      title: 'Experience Dubai',
      description: 'From skyscrapers to deserts, create unforgettable memories in Dubai.',
    },
  ];

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
      loop
      style={{ borderRadius: '16px' }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <Paper
            sx={{
              position: 'relative',
              color: '#fff',
              mb: 4,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url(${slide.image})`,
              borderRadius: '16px',
              height: '25rem',
            }}
          >
            {/* Hidden Image for SEO */}
            <img style={{ display: 'none' }} src={slide.image} alt={slide.title} />

            {/* Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,.4)',
                borderRadius: '16px',
                height: '25rem',
              }}
            />

            {/* Content */}
            <Grid container>
              <Grid item md={6}>
                <Box sx={{ position: 'relative', mt: 3, p: { xs: 3, md: 6 } }}>
                  <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                    {slide.title}
                  </Typography>
                  <Typography variant="h5" color="inherit" sx={{ mt: 1 }}>
                    {slide.description}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
                    <Button variant="contained" onClick={handleRedirect}>
                      Create Itinerary
                    </Button>
                    <Button variant="contained" onClick={handleRedirectViewPage}>
                      View All Itineraries
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FeaturedItineraries;
