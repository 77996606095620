import React, { useState } from 'react';
import FeaturedItineraries from '../../components/featuredItineraries/FeaturedItineraries';
import Box from '@mui/material/Box';
import HotPackage from '../../components/HotPackages/HotPackage';
import { Button, Grid } from '@mui/material';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useGetAllAdminItinerariesQuery } from '../../apis/itineraryApis/itineraryApi';
import { useGetAllLocationWiseCountryQuery } from '../../apis/locationsApi/locationsApi';
import CustomSelect from '../../components/customSelect/CustomSelect';
import { Field, Formik, Form } from 'formik';
import HotDealList from '../../components/HotDealsList/HotDealList';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Typography } from '@mui/material';

const Home = () => {
  const INITIAL_VALUES = {
    location: '',
    country: '',
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Fetch filtered itineraries based on searchParams
  const { data: itinerariesData } = useGetAllAdminItinerariesQuery(
    {
      keyword: searchParams.get('keyword') || '',
      location: searchParams.get('location') || '',
      country: searchParams.get('country') || '',
      page: 0,
      limit: 100,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: locationsResponse } = useGetAllLocationWiseCountryQuery();
  const locationsData = locationsResponse?.data || [];

  const locations = locationsData.map((location) => ({
    name: location.locationName,
    value: location.locationName,
  }));

  const countries = locationsData.reduce((acc, location) => {
    location.countries.forEach((country) => {
      if (!acc.some((item) => item.value === country)) {
        acc.push({ name: country, value: country });
      }
    });
    return acc;
  }, []);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleSubmit = (values) => {
    navigate({
      pathname: `/itineraries`,
      search: `?${createSearchParams(values)}`,
    });
  };

  const validateForm = (values) => {
    setIsButtonEnabled(values.location !== '' && values.country !== '');
  };

  return (
    <>
      <FeaturedItineraries />
      <Box
        sx={{
          background: '#ffffff',
          padding: '2rem',
          borderRadius: '12px',
          boxShadow: '2px 6px 10px rgba(0, 0, 0, 0.1)',
          mb: 3,
        }}
      >
        <Box sx={{ textAlign: 'left', mb: 2 }}>
          <Typography variant="h5" fontWeight="bold" >
            Plan and Book Your Holiday
          </Typography>
        </Box>

        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {({ isValid, values, handleChange }) => (
            <Form>
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={12} md={3}>
                  <Field
                    className="custom-select"
                    name="country"
                    fieldValue="value"
                    fieldName="name"
                    component={CustomSelect}
                    placeholder="Select Country"
                    options={countries}
                    isMulti={false}
                  />
                </Grid>
                <Grid item sm={12} md={3}>
                  <Field
                    className="custom-select"
                    name="location"
                    fieldValue="value"
                    fieldName="name"
                    component={CustomSelect}
                    placeholder="Select Location"
                    options={locations}
                    isMulti={false}
                  />
                </Grid>
                <Grid item sm={12} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    disabled={!isButtonEnabled}
                    sx={{ height: '100%' }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>

      <Box sx={{ paddingY: 2 }}>
        <HotPackage />
      </Box>
      <Grid container spacing={2}>
        {itinerariesData &&
          itinerariesData.data.length > 0 &&
          itinerariesData.data.map((eachItem) => (
            <Grid item md={3} sm={12} key={eachItem._id}>
              <HotDealList data={eachItem} />
            </Grid>
          ))}
      </Grid>

      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000,
        }}
      >
        <a
          href="https://wa.me/+971529406180"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundColor: '#4AC959',
            padding: '12px 16px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            width: '70px',
            height: '70px',
          }}
        >
          <WhatsAppIcon sx={{ color: '#fff', fontSize: 40 }} />
        </a>
      </Box>
    </>
  );
};

export default Home;
