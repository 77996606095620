
import { registerSchema } from "../../app/schema/authSchema"
import { useState } from "react"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { MenuItem } from "@mui/material"
import { browserSessionPersistence, createUserWithEmailAndPassword, setPersistence } from "firebase/auth"
import { auth } from "../../app/firebase/firebase.config"
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Box,
  Paper,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Avatar,
} from "@mui/material"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "./SignUp.css"
import { setCredentials, setToken } from "../../app/store/reducers/authSlice"
import { useRegisterMutation } from "../../apis/authApis/loginApi"
import data from "../data.json"
import { uploadImages } from "../../helpers/imageUploadHelper"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"
import BusinessIcon from "@mui/icons-material/Business"
import PersonIcon from "@mui/icons-material/Person"
import ReceiptIcon from "@mui/icons-material/Receipt"
import LockIcon from "@mui/icons-material/Lock"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"

const SignUp = () => {
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [register] = useRegisterMutation()

  const TOAST_CONFIG = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  }

  async function replaceImgWithUrls(formObj) {
    const logo = await uploadImages(formObj.company_logo, "user")
    formObj.company_logo = logo[0]
    const pan = await uploadImages(formObj.pan_document, "user")
    formObj.pan_document = pan[0]
    const gst = await uploadImages(formObj.gst_document, "user")
    formObj.gst_document = gst[0]
  }

  const submitHandler = async (values, actions) => {
    try {
      setPersistence(auth, browserSessionPersistence).then(async () => {
        const user = await createUserWithEmailAndPassword(auth, values.email, values.password)
        dispatch(setCredentials({ userId: user.user.uid, email: user.user.email }))
        dispatch(
          setToken({
            accessToken: user._tokenResponse.idToken,
            refreshToken: user._tokenResponse.refreshToken,
          }),
        )

        const UserData = {
          uId: user.user.uid,
          email: values.email,
          phonenumber: values.phonenumber,
          whatsapp_number: values.whatsapp_number,
          company_name: values.company_name,
          company_logo: values.company_logo,
          business_type: values.business_type,
          country_code: values.country_code,
          state: values.state,
          city: values.city,
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          website: values.website,
          pan_card_no: values.pan_card_no,
          pan_document: values.pan_document,
          gst_no: values.gst_no,
          gst_document: values.gst_document,
        }
        await replaceImgWithUrls(UserData).then(() => {
          register(UserData)
            .unwrap()
            .then(() => {
              actions.resetForm()
              toast.success(
                "This account will be verified shortly. For any questions, please contact admin",
                TOAST_CONFIG,
              )
            })
            .catch((err) => {
              console.error(err)
              TOAST_CONFIG.autoClose = 5000
              toast.error(`Error Occured: ${err.data?.message}`, TOAST_CONFIG)
            })
        })
      })
    } catch (err) {
      console.error(err)
      TOAST_CONFIG.autoClose = 5000
      toast.error(`Error Occured: ${err.data?.message}`, TOAST_CONFIG)
    }
  }

  const handleStateChange = (event) => {
    const selectedState = event.target.value
    const selectedStateData = data.states.find((state) => state.name == selectedState)

    if (selectedStateData) {
      setCities(selectedStateData.districts.map((district) => district.name))
    }
  }

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: registerSchema,
    onSubmit: submitHandler,
  })

  const steps = ["Company Information", "Personal Details", "Account Details", "Verification"]

  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ py: 0}}>
        <ToastContainer />
        <Paper sx={{ borderRadius: 3, overflow: "hidden" }}>
          <Grid >
            <Grid item xs={12} md={8}>
              <Box sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#1976d2", textAlign: "center" }}
                >
                  User Registration
                </Typography>

                <Stepper activeStep={-1} alternativeLabel sx={{ mb: 4, display: { xs: "none", sm: "flex" } }}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar sx={{ bgcolor: "#1976d2", mr: 2 }}>
                        <BusinessIcon />
                      </Avatar>
                      <Typography variant="h6" component="h2">
                        Company Information
                      </Typography>
                    </Box>
                    <Divider sx={{ mb: 3 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="company_name"
                          label="Company Name"
                          name="company_name"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.company_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.company_name && touched.company_name}
                          helperText={errors.company_name && touched.company_name && errors.company_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="business_type"
                          label="Business Type"
                          name="business_type"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.business_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.business_type && touched.business_type}
                          helperText={errors.business_type && touched.business_type && errors.business_type}
                          select
                        >
                          <MenuItem value="solo_proprietor">Solo Proprietor</MenuItem>
                          <MenuItem value="partnership">Partnership</MenuItem>
                          <MenuItem value="joint_venture">Joint Venture</MenuItem>
                          <MenuItem value="public_co_ltd">Public Co. Ltd.</MenuItem>
                          <MenuItem value="freelancer">Freelancer</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            border: "1px dashed #1976d2",
                            borderRadius: 1,
                            p: 2,
                            textAlign: "center",
                            backgroundColor: "rgba(25, 118, 210, 0.04)",
                          }}
                        >
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="company_logo"
                            name="company_logo"
                            type="file"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="company_logo">
                            <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />} sx={{ mb: 1 }}>
                              Upload Company Logo
                            </Button>
                          </label>
                          <Typography variant="caption" display="block">
                            {values.company_logo ? values.company_logo.split("\\").pop() : "No file selected"}
                          </Typography>
                          {errors.company_logo && touched.company_logo && (
                            <Typography variant="caption" color="error">
                              {errors.company_logo}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="state"
                          label="State"
                          name="state"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.state}
                          onChange={(e) => {
                            handleStateChange(e)
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                          error={errors.state && touched.state}
                          helperText={errors.state && touched.state && errors.state}
                          select
                        >
                          {data.states.map((state) => (
                            <MenuItem key={state.name} value={state.name}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="city"
                          label="City"
                          name="city"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.city && touched.city}
                          helperText={errors.city && touched.city && errors.city}
                          select
                        >
                          {cities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar sx={{ bgcolor: "#1976d2", mr: 2 }}>
                        <PersonIcon />
                      </Avatar>
                      <Typography variant="h6" component="h2">
                        Personal Information
                      </Typography>
                    </Box>
                    <Divider sx={{ mb: 3 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="first_name"
                          label="First Name"
                          name="first_name"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.first_name && touched.first_name}
                          helperText={errors.first_name && touched.first_name && errors.first_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="last_name"
                          label="Last Name"
                          name="last_name"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.last_name && touched.last_name}
                          helperText={errors.last_name && touched.last_name && errors.last_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="country_code"
                          label="Country Code"
                          name="country_code"
                          required
                          variant="outlined"
                          fullWidth
                          value={values.country_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.country_code && touched.country_code}
                          helperText={errors.country_code && touched.country_code && errors.country_code}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="phonenumber"
                          label="Phone Number"
                          name="phonenumber"
                          type="tel"
                          placeholder="123-456-7890"
                          fullWidth
                          defaultValue={values.phonenumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.phonenumber && touched.phonenumber}
                          helperText={errors.phonenumber && touched.phonenumber && errors.phonenumber}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="whatsapp_number"
                          label="Whatsapp Number"
                          name="whatsapp_number"
                          type="tel"
                          placeholder="123-456-7890"
                          required
                          fullWidth
                          defaultValue={values.whatsapp_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.whatsapp_number && touched.whatsapp_number}
                          helperText={errors.whatsapp_number && touched.whatsapp_number && errors.whatsapp_number}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="email"
                          label="Email"
                          name="email"
                          type="email"
                          placeholder="john@email.com"
                          fullWidth
                          defaultValue={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email && errors.email}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="website"
                          label="Website"
                          name="website"
                          variant="outlined"
                          fullWidth
                          value={values.website}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.website && touched.website}
                          helperText={errors.website && touched.website && errors.website}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="address"
                          label="Address"
                          name="address"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.address && touched.address}
                          helperText={errors.address && touched.address && errors.address}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar sx={{ bgcolor: "#1976d2", mr: 2 }}>
                        <ReceiptIcon />
                      </Avatar>
                      <Typography variant="h6" component="h2">
                        Account Details
                      </Typography>
                    </Box>
                    <Divider sx={{ mb: 3 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="pan_card_no"
                          label="PAN Card Number"
                          name="pan_card_no"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.pan_card_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.pan_card_no && touched.pan_card_no}
                          helperText={errors.pan_card_no && touched.pan_card_no && errors.pan_card_no}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            border: "1px dashed #1976d2",
                            borderRadius: 1,
                            p: 2,
                            textAlign: "center",
                            backgroundColor: "rgba(25, 118, 210, 0.04)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            accept="image/*,.pdf"
                            style={{ display: "none" }}
                            id="pan_document"
                            name="pan_document"
                            type="file"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="pan_document">
                            <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />} sx={{ mb: 1 }}>
                              Upload PAN Document
                            </Button>
                          </label>
                          <Typography variant="caption" display="block">
                            {values.pan_document ? values.pan_document.split("\\").pop() : "No file selected"}
                          </Typography>
                          {errors.pan_document && touched.pan_document && (
                            <Typography variant="caption" color="error">
                              {errors.pan_document}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="gst_no"
                          label="GST Number"
                          name="gst_no"
                          variant="outlined"
                          fullWidth
                          required
                          value={values.gst_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.gst_no && touched.gst_no}
                          helperText={errors.gst_no && touched.gst_no && errors.gst_no}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            border: "1px dashed #1976d2",
                            borderRadius: 1,
                            p: 2,
                            textAlign: "center",
                            backgroundColor: "rgba(25, 118, 210, 0.04)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            accept="image/*,.pdf"
                            style={{ display: "none" }}
                            id="gst_document"
                            name="gst_document"
                            type="file"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="gst_document">
                            <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />} sx={{ mb: 1 }}>
                              Upload GST Document
                            </Button>
                          </label>
                          <Typography variant="caption" display="block">
                            {values.gst_document ? values.gst_document.split("\\").pop() : "No file selected"}
                          </Typography>
                          {errors.gst_document && touched.gst_document && (
                            <Typography variant="caption" color="error">
                              {errors.gst_document}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar sx={{ bgcolor: "#1976d2", mr: 2 }}>
                        <LockIcon />
                      </Avatar>
                      <Typography variant="h6" component="h2">
                        Verification Details
                      </Typography>
                    </Box>
                    <Divider sx={{ mb: 3 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          label="Confirm Email"
                          name="email"
                          type="email"
                          placeholder="john@email.com"
                          fullWidth
                          defaultValue={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email && errors.email}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="password"
                          label="Password"
                          type="password"
                          name="password"
                          placeholder="••••••••"
                          variant="outlined"
                          fullWidth
                          required
                          defaultValue={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password && touched.password}
                          helperText={errors.password && touched.password && errors.password}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          name="confirmPassword"
                          placeholder="••••••••"
                          variant="outlined"
                          fullWidth
                          required
                          defaultValue={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.confirmPassword && touched.confirmPassword}
                          helperText={errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
                      Note: Fields marked with * are mandatory.
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        sx={{
                          px: 4,
                          py: 1.5,
                          borderRadius: 2,
                          boxShadow: "0 4px 6px rgba(25, 118, 210, 0.25)",
                          "&:hover": {
                            boxShadow: "0 6px 10px rgba(25, 118, 210, 0.3)",
                          },
                        }}
                      >
                        Sign Up
                      </Button>
                      <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        size="large"
                        sx={{
                          px: 4,
                          py: 1.5,
                          borderRadius: 2,
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>

                  <Box sx={{ textAlign: "center", mt: 3 }}>
                    <Typography variant="body1">
                      Already have an account?{" "}
                      <Link
                        to="/signin"
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          color: "#1976d2",
                        }}
                      >
                        Sign In
                      </Link>
                    </Typography>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default SignUp

