import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

export default function Header() {
  return (
    <div>
      <header className="bg-white">
        <nav
          className="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5 flex items-center">
              <img
                src="https://ik.imagekit.io/freelance/BOB%20Logo%20Final%20Approved-01%20(2).jpg?updatedAt=1741718709874"
                width="50"  
                height="60" 
                className="w-[80px] h-[80px]"
                alt="Company Logo"
              />
            </a>
          </div>
          <div className="flex space-x-4 lg:hidden">
            <a href="tel:+971529406180" className="flex items-center">
              <PhoneIcon />
              <span className="mr-2">+971529406180</span>
            </a>
            <a href="mailto:info@skyhighmds.com" className="flex items-center">
              <MailIcon />
              <span className="ml-2">info@skyhighmds.com</span>
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
}
