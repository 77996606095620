import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

export default function TermsAndConditions() {
    return (
        <>
            <div>
                <div className="p-8 bg-white border border-gray-200 rounded-lg shadow privacy-policy dark:bg-gray-200 dark:border-gray-200">
                    <h1 className="mb-4 text-3xl font-bold">Terms and Conditions</h1>
                    
                    <p><strong>Terms And Conditions</strong></p>
                    <p>All correspondence(s) in respect of Tours / Travel Service bookings should be addressed to M/s. SKYHIGH TOURS AND TRAVELS</p>
                    
                    <h2 className="mt-6 mb-2 text-2xl font-semibold">Payments:</h2>
                    <p>For all the services contracted, certain advance payment should be made to hold the booking, on confirmed basis & the balance amount can be paid either before your departure from your country or upon arrival in INDIA but definitely before the commencement of the services. Management personnel’s hold the right to decide upon the amount to be paid as advance payment, based on the nature of the service & the time left for the commencement of the service.</p>
                    <p>Apart from above in some cases like Special Train Journeys, hotels or resorts bookings during the peak season (X-Mas, New Year), full payment is required to be sent in advance.</p>
                    
                    <h2 className="mt-6 mb-2 text-2xl font-semibold">Mode of Payment:</h2>
                    <p>Overseas advance payment can be made through Wire Transfer to our bank.</p>
                    
                    <h2 className="mt-6 mb-2 text-2xl font-semibold">Cancellation Policy:</h2>
                    <p>In the event of cancellation of tour / travel services due to any avoidable / unavoidable reason/s we must be notified of the same in writing. Cancellation charges will be effective from the date we receive advice in writing, and cancellation charges would be as follows:</p>
                    <ul className="ml-6 list-disc">
                        <li>45 days prior to arrival: 10% of the Tour / service cost</li>
                        <li>15 days prior to arrival: 25% of the Tour / service cost</li>
                        <li>07 days prior to arrival: 50% of the Tour / service cost</li>
                        <li>48 hours prior to arrival OR No Show: No Refund</li>
                    </ul>
                    <p>Note: Written cancellation will accept on all working days, except Sunday, Any cancellation sent on Sunday's will be considered on next working day (Monday).</p>
                    <p>For the X-mas and new year period from 20 Dec to 05 Jan the payment is non-refundable.</p>
                    <p>In case you cancel the trip after commencement, refund would be restricted to a limited amount only which too would depend on the amount that we would be able to recover from the hoteliers/ contractors we patronize. For unused hotel accommodation, chartered transportation & missed meals etc. we do not bear any responsibility to refund.</p>
                    
                    <h2 className="mt-6 mb-2 text-2xl font-semibold">Refund Policy</h2>
                    <p>The applicable refund amount will be processed within 10 business days</p>
                    <p>All applicable refunds will be done in SKYHIGH TOURS AND TRAVELS</p>
                    
                    <h2 className="mt-6 mb-2 text-2xl font-semibold">Booking Confirmation Policy</h2>
                    <p>The customer receives a confirmation voucher via email within 24 hours of successful booking</p>
                    <p>In case the preferred slots are unavailable, an alternate schedule of the customer’s preference will be arranged and a new confirmation voucher will be sent via email.</p>
                    <p>Alternatively, the customer may choose to cancel their booking before confirmation and a full refund will be processed.</p>
                    
                    <h2 className="mt-6 mb-2 text-2xl font-semibold">Our Liabilities & Limitations:</h2>
                    <p>Please note that after the finalization of the Tour/ service Cost, if there are any Hike in entrance fees of monuments / museums, Taxes, fuel cost or guide charges – by Govt , the same would be charged as extra.</p>
                    <p>All itineraries are sample itineraries, intended to give you a general idea of the likely trip schedule. Numerous factors such as weather, road conditions, the physical ability of the participants etc. may dictate itinerary changes either before the tour or while on the trail. We reserve the right to change any schedule in the interest of the trip participants' safety, comfort & general well being.</p>
                    <p>Our rates are based on the prevailing rates as negotiated by us with the hotels, airlines etc. Hotels and Airlines retain the right to modify the rates without notice. In case of such changes the rates quoted before the modification, can be changed by us according to the modifications by hotels or airlines. All hotel bookings are based on usual check in and check out time of the hotels until unless indicated in the itinerary.</p>
                    <p>We shall not be responsible for any delays & alterations in the programme or expenses incurred – directly or indirectly – due to natural hazards, flight cancellations, accident, breakdown of machinery or equipments, breakdown of transport, weather, sickness, landslides, political closures or any untoward incidents.</p>
                    <p>We shall not be responsible for any loss, injury or damage to person, property, or otherwise in connection with any accommodation, transportation or other services, resulting – directly or indirectly – from any act of GOD, dangers, fire, accident, breakdown in machinery or equipment, breakdown of transport, wars, civil disturbances, strikes, riots, thefts, pilferages, epidemics, medical or custom department regulations, defaults, or any other causes beyond our control.</p>
                    <p>We do not have any insurance policy covering the expenses for accident, sickness, loss due to theft, or any other reasons. Visitors are advised to seek such insurance arrangements in their home country. All baggage’s & personal property/s at all times are at the client's risk.</p>
                </div>
            </div>
            <Footer />
        </>
    );
}
